import React, { useState } from "react";
import fetchIntercept from "fetch-intercept";

import { tokenResponse, OauthProtectProps } from "./types";

const tokenKey = "id_token";

export function redirect(): void {
  const clientId = encodeURIComponent(process.env.REACT_APP_CLIENT_ID + "");
  const authorizeUrl = process.env.REACT_APP_AUTHORIZE_URL;
  const redirectUri = window.location.origin;
  window.location.href = `${authorizeUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;

}

function renderAccessDenied(): React.ReactNode {
  return <span></span>;
}

function isNotAuthenticated(): boolean {
  return sessionStorage.getItem(tokenKey) ? false : true;
}

async function requestToken(code: string): Promise<tokenResponse | null> {
  const redirectUri = window.location.origin;
  const tokenUrl = process.env.REACT_APP_REQUEST_TOKEN_URL || "";
  const clientId = encodeURIComponent(process.env.REACT_APP_CLIENT_ID + "");

  var urlencoded = new URLSearchParams();
  urlencoded.append("code", code);
  urlencoded.append("client_id", clientId);
  urlencoded.append("grant_type", "authorization_code");
  urlencoded.append("redirect_uri", redirectUri);

  const response = await fetch(
    `${tokenUrl}`, //?code=${code}&client_id=${clientId}&grant_type=authorization_code&redirect_uri=http://localhost:3000
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    }
  );
  const json = await response.json();
  //console.log(json)
  
  return {
    id_token: json["id_token"],
    access_token: json["access_token"],
    refresh_token: json["refresh_token"],
    token_type: json["token_type"],
    expires_in: json["expires_in"],
  };
}



function getCode(): string | null {
  const params = new URL(window.location.href).searchParams;
  return params.get("code");
}

function registerRequestInterceptToken(): void {
  fetchIntercept.register({
    request: function (url: string, config: any) {
      console.log("requisicao interceptada");
      
      console.log(url)
    
      

      const token = sessionStorage.getItem(tokenKey);

      if (!token) redirect();

      if (!config) config = {};

      let headers = config.headers || {};

      
      config.headers = Object.assign(headers, {
        Authorization: `Bearer ${token}`,
      });

      return [url, config];
    
    },
    responseError: function (error) {
      if (error){
        sessionStorage.clear()
        localStorage.clear()
        redirect()
      }
      
      return Promise.reject(error);
  }

  });
}




async function validate(
  
  setAuth: React.Dispatch<React.SetStateAction<boolean>>
) {

  if (isNotAuthenticated()) {
    const code = getCode();

    if (code) {
      const response = await requestToken(code);
      if (response) {
        for (const item in response) {
          sessionStorage.setItem(item, response[item]);
        }
        setAuth(true);
      }
    } else redirect();
  } else setAuth(true);
}

export default ({ children }: OauthProtectProps) => {
    // hulk do dani :D
  const [authenticated, setAuth] = useState(false);

  if (!authenticated) validate(setAuth);
  else registerRequestInterceptToken();

  return (
    <React.Fragment>
      {authenticated ? children : renderAccessDenied()}
    </React.Fragment>
  );
};
